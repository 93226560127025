import { useDocumentTitle } from "@uidotdev/usehooks";
import React, { FC, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { Button, Input, Loading, PasswordInput } from "../components";
import { config } from "../config";
import { SignUpBody, useAuth, useTranslations } from "../contexts";
import { routerPaths } from "./routerPaths";

interface SignInOrSignUpProps {
  variant: "signIn" | "signUp";
}

const SignInOrSignUpForm: FC<SignInOrSignUpProps> = (props) => {
  const auth = useAuth();
  const i18n = useTranslations();
  const [form, setForm] = useState<Omit<SignUpBody, "language">>({
    email: "",
    password: "",
  });
  if (auth.isLoading) {
    return <Loading />;
  }
  if (auth.user?.id) {
    return <Navigate to={routerPaths.dashboard} />;
  }
  return (
    <form
      className="space-y-4 md:space-y-6"
      onSubmit={(event) => {
        event.preventDefault();
        if (props.variant === "signIn") {
          auth.signIn(form);
        }
        if (props.variant === "signUp") {
          auth.signUp({ ...form, language: navigator.language.slice(0, 2) });
        }
      }}
    >
      <div>
        <Input
          type="email"
          id="email"
          placeholder={i18n["Your email"]}
          onChange={(event) => setForm({ ...form, email: event.target.value })}
        />
      </div>
      <div>
        <PasswordInput
          onChange={(event) =>
            setForm({ ...form, password: event.target.value })
          }
        />
      </div>
      {auth.errors.map(({ message }) => (
        <p className="text-sm text-red-500 error">{message}</p>
      ))}
      <Button loading={auth.isLoading} type="submit" className="w-full">
        {props.variant === "signIn" && i18n["signIn"]}
        {props.variant === "signUp" && i18n["signUp"]}
      </Button>
      {props.variant === "signUp" && (
        <div className="flex justify-between text-sm font-light text-gray-500 ">
          <p>
            {i18n["Already have an account?"]}{" "}
            <Link
              to={routerPaths.signIn}
              className="font-medium text-primary-600 hover:underline "
            >
              {i18n["signIn"]}
            </Link>
          </p>
        </div>
      )}
      {props.variant === "signIn" && (
        <div className="flex  justify-between text-sm font-light text-gray-500 ">
          <p>
            {i18n["Don't have an account yet?"]}{" "}
            <Link
              to={routerPaths.signUp}
              className="font-medium text-primary-600 hover:underline "
            >
              {i18n["signUp"]}
            </Link>
          </p>
          <a
            href="#"
            className="text-sm font-medium text-primary-600 hover:underline "
          >
            {i18n["Forgotten password?"]}
          </a>
        </div>
      )}
    </form>
  );
};

export const SignInOrSignUp: FC<SignInOrSignUpProps> = (props) => {
  const i18n = useTranslations();
  useDocumentTitle(
    `${config.app.name} - ${
      {
        signIn: i18n["signIn"],
        signUp: i18n["signUp"],
      }[props.variant]
    }`
  );

  return (
    <section className="h-full">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto h-screen lg:py-0">
        <h1 className="logo magictime vanishIn text-7xl py-6">Zuv</h1>
        <div className="magictime vanishIn w-full  rounded-lg shadow -0 sm:max-w-md xl:p-0  ">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <SignInOrSignUpForm {...props} />
          </div>
        </div>
      </div>
    </section>
  );
};
