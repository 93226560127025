import { Check, Trash } from "lucide-react";
import React, {
  FormEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, InputWithButton, Loading } from "../../../components";
import { useApi, useAuth, useTranslations } from "../../../contexts";
import { client } from "../../../helpers";
import { Domain } from "../../../types";
import { routerPaths } from "../../routerPaths";

const DomainStatus: React.FC<{ name: string }> = ({ name }) => {
  const [status, setStatus] = useState<string | null>();
  const [error, setError] = useState<string | null>();
  const [loading, setLoading] = useState(false);
  const auth = useAuth();
  const fetchDomainStatus = useCallback(async () => {
    if (status || !auth.token || loading) return;
    setLoading(true);
    try {
      const results = await (
        await client.api.domains[":name"].status.$get(
          {
            param: { name },
          },
          { headers: { authorization: `Bearer ${auth.token}` } }
        )
      ).json();
      if (results.success) {
        setStatus(results.data.domain.status);
        setError(results.data.domain.verification_errors);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [auth.token]);
  useEffect(() => {
    fetchDomainStatus();
  }, [auth.token]);
  if (error) return error;
  return status === "active" ? (
    <Check className="text-green-500 me-2" />
  ) : (
    <Loading className="me-2" />
  );
};

const DomainItem: React.FC<{
  domain: Domain;
  siteId: string;
}> = (props) => {
  const auth = useAuth();
  const headers = useMemo(
    () => ({
      authorization: `Bearer ${auth.token}`,
    }),
    [auth.token]
  );
  const i18n = useTranslations();
  const api = useApi();
  const deleteDomain = async () => {
    if (!window.confirm(i18n["Are you sure?"])) {
      return;
    }
    const results = await (
      await client.api.domains[":name"].$delete(
        {
          param: { name: props.domain.name },
        },
        { headers }
      )
    ).json();
    if (!results.success) {
      console.error(results.error);
      toast.error(results.error.issues[0].message);
    } else {
      toast.success(i18n["Domain deleted"]);
      api.queryClient.invalidateQueries({
        queryKey: ["site", props.siteId],
      });
    }
  };
  return (
    <div>
      <div className="flex items-center p-4">
        <span className="flex grow">
          <DomainStatus name={props.domain.name} />
          {props.domain.name}
        </span>
        <Button
          variant="destructive"
          size="sm"
          icon={<Trash />}
          onClick={() => {
            deleteDomain();
          }}
        />
      </div>
      <div className="bg-white m-3 p-3 rounded">
        <p className="font-bold pb-3">CNAME</p>
        <p className="pb-2">
          <code>{`_acme-challenge.${props.domain.name} → ${props.domain.name}.c6e6fab17a483c2b.dcv.cloudflare.com`}</code>
        </p>
        <p className="pb-2">
          <code>{`${props.domain.name} → proxy.zuv.app`}</code>
        </p>
      </div>
    </div>
  );
};

const AddDomainForm: React.FC<{ siteId: string }> = ({ siteId }) => {
  const [domain, setDomain] = useState("");
  const auth = useAuth();
  const i18n = useTranslations();
  const api = useApi();
  const handleSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();
    const results = await (
      await client.api.sites[":siteId"].domains.$post(
        {
          json: { domain },
          param: { siteId },
        },
        { headers: { authorization: `Bearer ${auth.token}` } }
      )
    ).json();
    if (!results.success) {
      toast.error(i18n["Something went wrong"]);
    } else {
      api.queryClient.invalidateQueries({
        queryKey: ["site", siteId],
      });
      setDomain("");
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <InputWithButton
        inputProps={{
          className: "grow",
          variant: "outlined",
          type: "text",
          placeholder: "Domain",
          value: domain,
          onChange: (event) => setDomain(event.target.value),
        }}
        buttonProps={{
          label: "add",
          type: "submit",
        }}
      />
    </form>
  );
};

export const DomainsTab: React.FC = () => {
  const { siteId } =
    useParams<Parameters<typeof routerPaths.siteManage>["0"]>();
  if (!siteId) throw new Error(`no siteId in url params`);
  const api = useApi();
  const site = api.siteById({ siteId })?.data;
  if (api.siteById({ siteId })?.isLoading) {
    return <Loading />;
  }
  if (!site) {
    return <div>Not found</div>;
  }
  return (
    <div id="site-manage-domains-tab pb-4">
      {site?.domains?.length ? (
        <div className="bg-slate-100 border rounded mb-4">
          {site.domains.map((domain) => (
            <DomainItem key={domain.name} domain={domain} siteId={siteId} />
          ))}
        </div>
      ) : null}
      <AddDomainForm siteId={siteId} />
      <div className="border mt-6" />
      <DomainInfo />
    </div>
  );
};

const DomainInfo = () => {
  const i18n = useTranslations();
  return (
    <div className="mt-6 p-4 rounded border bg-sky-100">
      <p>
        {
          i18n[
            "A domain is the unique web address (e.g. yourwebsite.com) that people use to access your site."
          ]
        }
      </p>
      <p className="py-2 font-bold">
        {
          i18n[
            "You will need to set up TWO CNAME records with your domain registrar"
          ]
        }
      </p>
      <p className="py-2">
        {
          i18n[
            "Here are the links to the documentation pages for various popular registrars"
          ]
        }
        :{" "}
        <a className="text-blue-500" href={i18n["CNAME doc links - Ionos"]}>
          Ionos
        </a>
        ,{" "}
        <a className="text-blue-500" href={i18n["CNAME doc links - OVH"]}>
          OVH
        </a>
        .
      </p>
      <p className="py-2">
        {i18n["If you encounter any difficulties, please contact us"]}.
      </p>
    </div>
  );
};
