import React from "react";
import type { RenderSiteProps } from "./RenderSite";

export const Features: React.FC<RenderSiteProps> = (props) => {
  if (!props.site.services) {
    return null;
  }
  return (
    <section
      id="features"
      className="py-6 bg-gradient-to-b from-gray-900 to-gray-800"
    >
      {props.site.services.title && (
        <h2 className="text-white text-center py-8 text-2xl font-bold">
          {props.site.services.title}
        </h2>
      )}

      <div className="m-auto max-w-7xl px-6 grid grid-cols-1 lg:grid-cols-2">
        {props.site.services?.items?.map((item, index) => (
          <article className="p-6 mx-auto" key={item.id || `service-${index}`}>
            <h3 className="text-xl font-semibold text-white mb-2">
              {item.title}
            </h3>
            {item.description?.split("\n").map((itemDescription, index) => (
              <p key={`${item.id}-${index}`} className="text-gray-400">
                {itemDescription}
              </p>
            ))}
          </article>
        ))}
      </div>
    </section>
  );
};
