const confirmDeleteWord = "DELETE";

export const en = {
  sites: "sites",
  settings: "settings",
  save: "save",
  add: "Add",
  edit: "edit",
  new: "new",
  delete: "Delete",
  "create a new site": "create a new site",
  name: "Name",
  dashboard: "dashboard",
  cancel: "cancel",
  signUp: "Sign Up",
  signIn: "Sign In",
  "Sign Out": "Sign Out",
  "Your email": "Your email",
  username: "Username",
  password: "Password",
  language: "language",
  "is available": "is available",
  "is already taken": "is already taken",
  title: "Title",
  description: "Description",
  button: "Button",
  introduction: "Introduction",
  "Main button": "Main Button",
  link: "Link",
  "Site updated": "Site updated",
  "Contact us": "Contact Us",
  "Still have questions? Get in touch.": "Still have questions? Get in touch.",
  logo: "Logo",
  designer: "Designer",
  contactForm: "contact form",
  "Your settings have been updated": "Your settings have been updated",
  dangerZone: "danger zone",
  "This action cannot be reverted or undone": [
    "This action cannot be reverted or undone.",
    "Once deleted, all data",
    "will be permanently removed and cannot be recovered",
  ].join(" "),
  "Are you sure?": "Are you sure?",
  "delete your account": "delete your account",
  "Are you sure you want to delete this site? To confirm, type DELETE.": [
    `Are you sure you want to delete this site? To confirm, type "${confirmDeleteWord}".`,
    "This action cannot be reverted or undone.",
  ].join(" "),
  confirmDeleteWord,
  "Your account has been deleted": "Your account has been deleted.",
  "Already have an account?": "Already have an account?",
  "Don't have an account yet?": "Don't have an account yet?",
  "Forgotten password?": "Forgotten password?",
  "Something went wrong": "Opps... something went wrong!",
  "Our services": "Our services",
  general: "General",
  domains: "Domains",
  "Domain deleted": "Domain deleted",
  seo: "SEO",
  ai: "AI",
  billings: "Billings",
  "Keywords describe your content and help search engines categorize it": [
    `Keywords describe your content and help search engines categorize it.`,
    `However, because too many abuse them, search engines now prioritize how`,
    `well your content answers users’ questions. Use keywords naturally, but`,
    `don’t force them. Instead, focus on creating clear, relevant, and`,
    `detailed content.`,
  ].join(" "),
  "A domain is the unique web address (e.g. yourwebsite.com) that people use to access your site.":
    "A domain is the unique web address (e.g. yourwebsite.com) that people use to access your site.",
  "You will need to set up TWO CNAME records with your domain registrar":
    "You will need to set up TWO CNAME records with your domain registrar",
  "Here are the links to the documentation pages for various popular registrars":
    "Here are the links to the documentation pages of popular registrars",
  "If you encounter any difficulties, please contact us":
    "If you encounter any difficulties, please contact us",
  "CNAME doc links - OVH":
    "https://help.ovhcloud.com/csm/en-gb-email-cname-record?id=kb_article_view&sysparm_article=KB0053251",
  "CNAME doc links - Ionos":
    "https://www.ionos.com/help/domains/configuring-cname-records-for-subdomains/configuring-a-cname-record-for-a-subdomain",
};
