import React from "react";
import { RenderSiteProps } from "./RenderSite";

const Gallery: React.FC<RenderSiteProps> = ({ site: { photos } }) => {
  if (!photos?.is_enabled) {
    return null;
  }
  return (
    <section id="gallery" className="py-16 bg-gray-900">
      <div className="mx-auto px-6">
        <h2 className="text-4xl font-bold text-white text-center mb-12">
          Photos
        </h2>
        <div className="flex flex-wrap justify-center gap-6">
          {photos.items?.map((photo, index) => (
            <div
              key={index}
              className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 overflow-hidden rounded-lg shadow-lg hover:shadow-2xl transition duration-300 ease-in-out"
            >
              <img
                src={photo.uri}
                alt={`Photo ${index + 1}`}
                className="w-full h-full object-cover"
                loading="lazy"
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Gallery;
