import React, { createContext, useContext, useEffect, useState } from "react";
import { en } from "./i18n/en";
import { getI18n } from "./i18n";
import { useAuth } from "./AuthContext";

type Translation = typeof en;

interface TranslationContextType extends Translation {
  setLanguage: (lang: string) => void;
}

// Initialize context with proper shape including setLanguage
const TranslationContext = createContext<TranslationContextType>({
  ...en,
  setLanguage: () => {}, // Default dummy function
});

export function TranslationProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const auth = useAuth();
  const [language, setLanguage] = useState<string>(
    navigator.language.slice(0, 2) || "en"
  );

  useEffect(() => {
    if (auth.user?.language) {
      setLanguage(auth.user.language);
    }
  }, [auth.user?.language]);

  const translations = getI18n(language);
  const contextValue = {
    ...translations,
    setLanguage: (lang: string) => setLanguage(lang),
  };

  return (
    <TranslationContext.Provider value={contextValue}>
      {!auth.isLoading && children}
    </TranslationContext.Provider>
  );
}

export function useTranslations() {
  return useContext(TranslationContext);
}
