export const routerPaths = {
  signIn: "/sign-in",
  signUp: "/sign-up",
  dashboard: "/dashboard",
  siteManage: ({
    siteId,
    tab,
  }: {
    siteId: string;
    tab?:
      | "general"
      | "domains"
      | "seo"
      | "ai"
      | "billings"
      | ":tab"
      | "undefined";
  }) => `/site/${siteId}/manage/${tab}`,
  siteNew: "/sites/new",
  siteEdit: ({ siteId }: { siteId: string }) => `/site/${siteId}/edit`,
  settings: "/settings",
};
