import React from "react";
import { defaultsDeep } from "lodash";
import { ImageUpload, ToggleSwitch } from "../../../../components";
import { SidebarFormProps } from "./SidebarForm";
import { client } from "../../../../helpers";

export const SidebarFormPhotos: React.FC<SidebarFormProps> = (props) => {
  const isEnabled = Boolean(props.form.photos?.is_enabled);

  return (
    <div id="photos-form" className="border-b p-6">
      <div className="flex ">
        <h2 className="grow font-bold pb-4  text-xl">Photos</h2>
        <ToggleSwitch
          checked={isEnabled}
          onChange={() =>
            props.setForm({
              ...props.form,
              photos: {
                ...props.form.photos,
                is_enabled: !isEnabled,
              },
            })
          }
        />
      </div>
      {isEnabled && (
        <div className="grid grid-cols-3 gap-3">
          {(props.form.photos?.items || []).map((photo, index) => (
            <ImageUpload
              key={photo.uri}
              image={`${photo.uri}?site_id=${props.form.id}`}
              onChange={({ uri }) => {
                props.setForm({
                  ...props.form,
                  photos: {
                    ...props.form.photos,
                    items: (props.form.photos?.items || []).map(
                      (item, _index) =>
                        index !== _index ? item : { ...item, uri }
                    ),
                  },
                });
              }}
              onDelete={() => {
                props.setForm({
                  ...props.form,
                  photos: {
                    ...props.form.photos,
                    items:
                      props.form.photos?.items?.filter(
                        (_, _index) => index !== _index
                      ) || [],
                  },
                });
              }}
            />
          ))}
          <ImageUpload
            onChange={({ uri }) => {
              props.setForm({
                ...props.form,
                photos: {
                  ...props.form.photos,
                  items: [...(props.form.photos?.items || []), { uri }],
                },
              });
            }}
          />
        </div>
      )}
    </div>
  );
};
