import { XIcon } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Input, Loading } from "../../../components";
import { useApi, useAuth, useTranslations } from "../../../contexts";
import { client } from "../../../helpers";
import { routerPaths } from "../../routerPaths";

export const SEOTab: React.FC = () => {
  const { siteId } =
    useParams<Parameters<typeof routerPaths.siteManage>["0"]>();
  if (!siteId) throw new Error(`no siteId in url params`);
  const [loading, setLoading] = useState(false);
  const [keywords, setKeywords] = useState<string>("");
  const [keywordInput, setKeyworkInput] = useState<string>("");
  const auth = useAuth();
  const api = useApi();
  const site = api.siteById({ siteId })?.data;
  const i18n = useTranslations();

  useEffect(() => {
    site?.seo_keywords && setKeywords(site?.seo_keywords.join(","));
  }, [site?.seo_keywords]);
  const updateKeywords = async (newKeywords: string) => {
    setLoading(true);
    try {
      const results = await (
        await client.api.sites[":siteId"].$patch({
          json: { seo_keywords: newKeywords.split(",") },
          header: { authorization: `Bearer ${auth.token}` },
          param: { siteId },
        })
      ).json();
      if (!results.success) {
        toast.error(i18n["Something went wrong"]);
        console.error(results.error.issues[0].message);
      } else {
        setKeywords(newKeywords);
        setKeyworkInput("");
      }
    } catch (error) {
      toast.error(i18n["Something went wrong"]);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  if (api.siteById({ siteId })?.isLoading) return <Loading />;
  return (
    <div id="site-manage-seo-tab">
      <div className="flex flex-wrap gap-2">
        {keywords
          .split(",")
          .filter((a) => a)
          .map((keyword) => (
            <button
              key={keyword}
              className="bg-slate-200 p-2 ps-4 flex items-center cursor-pointer rounded hover:bg-slate-400"
              onClick={(event) => {
                event.preventDefault();
                updateKeywords(
                  keywords
                    .split(",")
                    .filter((k) => k !== keyword)
                    .join(",")
                );
              }}
            >
              {keyword}
              <div className="ms-2  p-2">
                <XIcon className="size-3 " />
              </div>
            </button>
          ))}
      </div>
      {keywords && <div className="mt-5"></div>}

      <form
        className="flex items-center h-full"
        onSubmit={(event) => {
          event.preventDefault();
          updateKeywords(`${keywords},${keywordInput}`);
        }}
      >
        <Input
          variant="outlined"
          type="text"
          placeholder="Keyword"
          value={keywordInput}
          onChange={(event) => setKeyworkInput(event.target.value)}
        />
        <Button className="py-3 ms-4" type="submit" loading={loading}>
          Add
        </Button>
      </form>
      <p className="mt-4">
        Keywords describe your content and help search engines categorize it.
        However, because too many abuse them, search engines now prioritize how
        well your content answers users’ questions. Use keywords naturally, but
        don’t force them. Instead, focus on creating clear, relevant, and
        detailed content.
      </p>
    </div>
  );
};
