import React from "react";
import { RiEyeCloseLine, RiEyeLine } from "react-icons/ri";
import { useToggle } from "usehooks-ts";
import { Input, InputProps } from "./Input";
import { useAuth, useTranslations } from "../contexts";

interface PasswordInput extends InputProps {}

export const PasswordInput: React.FC<PasswordInput> = ({ type, ...props }) => {
  const [showPassword, toggleShowPassword] = useToggle(false);
  const auth = useAuth();
  const i18n = useTranslations();
  return (
    <div className="relative">
      <Input
        type={showPassword ? "text" : "password"}
        {...props}
        placeholder={i18n.password}
      />
      <div
        className="absolute top-1/2 right-3 transform -translate-y-1/2 cursor-pointer"
        onClick={() => toggleShowPassword()}
      >
        {showPassword ? <RiEyeLine size={20} /> : <RiEyeCloseLine size={20} />}
      </div>
    </div>
  );
};
