import React from "react";
import { Hero } from "./Hero";

import { hc, InferResponseType } from "hono/client";
import { SitesRoute } from "../api/sites";
import { ContactForm } from "./ContactForm";
import { Features } from "./Features";
import Gallery from "./Photos";

const client = hc<SitesRoute>("/");

export interface RenderSiteProps {
  site: (InferResponseType<(typeof client.api)["sites"][":siteId"]["$get"]> & {
    success: true;
  })["data"]["site"];
}

export const RenderSite: React.FC<RenderSiteProps> = (props) => {
  return (
    <div>
      <Hero {...props} />
      {props.site.services?.is_enabled && <Features {...props} />}
      {/* {props.site<About {...props} />} */}
      {props.site.photos?.is_enabled && <Gallery {...props} />}
      {props.site.contactForm?.is_enabled && <ContactForm {...props} />}
    </div>
  );
};
