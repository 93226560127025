import { InferResponseType } from "hono/client";
import React, { useRef, useState } from "react";

import { UploadCloudIcon, XIcon } from "lucide-react";
import { Nullish } from "utility-types";
import { useAuth } from "../contexts";
import { client } from "../helpers";

type ClientImageUpload = (typeof client.api)["images"]["upload"];
type ClientSitePostResponse = InferResponseType<ClientImageUpload["$post"]>;

interface ImageUploadProps {
  className?: string;
  image?: string | Nullish;
  onChange?: (params: { uri: string }) => void;
  onDelete?: () => void;
}

export const ImageUpload: React.FC<ImageUploadProps> = (props) => {
  const auth = useAuth();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [imageFile, setImageFile] = useState<File | Nullish>(null);
  const [progress, setProgress] = useState(0);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const [file] = e.target.files || [];
    if (!file) return;
    setImageFile(file);
    handleUpload(file);
  };

  const handleUpload = (file: File) => {
    if (!file) return;
    const formData = new FormData();
    formData.append("image", file);
    const xhr = new XMLHttpRequest();
    xhr.upload.addEventListener("progress", (event) => {
      if (event.lengthComputable) {
        setProgress(Math.round((event.loaded / event.total) * 100));
      }
    });

    xhr.open("POST", client.api.images.upload.$url());
    xhr.setRequestHeader("Authorization", [`Bearer`, auth.token].join(" "));
    xhr.send(formData);

    xhr.addEventListener("load", () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        const response: ClientSitePostResponse = JSON.parse(xhr.responseText);
        if (!response.success) {
          console.error(response.error);
          return;
        }
        props.onChange &&
          props.onChange({
            uri: response.data.image.uri,
          });
        setImageFile(null);
      } else {
        console.error(`Error uploading file: ${xhr.statusText}`);
      }
    });

    xhr.addEventListener("error", () => {
      console.error("Error uploading file");
    });
  };

  const imageSrc = imageFile ? URL.createObjectURL(imageFile) : props.image;
  return (
    <div className={`h-full w-full ${props.className}`}>
      <div className="h-full w-full relative">
        <input
          className="hidden"
          type="file"
          accept="image/png, image/jpeg, image/gif, image/webp"
          ref={fileInputRef}
          onChange={handleChange}
        />

        <div
          onClick={() => fileInputRef.current?.click()}
          className="h-full cursor-pointer hover:border-gray-700 border-transparent border-2 hover:border-current border-dashed overflow-hidden rounded-lg transition duration-300 ease-in-out"
        >
          {imageSrc ? (
            <>
              <img
                src={imageSrc}
                alt="preview"
                className="w-full h-full object-cover"
              />
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setImageFile(null);
                  props.onDelete && props.onDelete();
                }}
                className="absolute top-1 right-1 p-2 w-1/8 h-1/8 rounded-full bg-white  hover:bg-gray-200"
                aria-label="Delete image"
              >
                <XIcon className="text-gray-700 hover:text-red-500" />
              </button>
            </>
          ) : (
            <UploadCloudIcon className="w-full h-full object-cover text-gray-500" />
          )}
          {progress > 0 && progress < 100 && (
            <div className="p-1">
              <div className="w-full bg-gray-200 rounded-full h-2">
                <div
                  className="bg-blue-600 h-2 rounded-full"
                  style={{ width: `${progress}%` }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
