import React, {
  Dispatch,
  FormEventHandler,
  SetStateAction,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useApi, useAuth, useTranslations } from "../../../../contexts";
import { client } from "../../../../helpers";
import { routerPaths } from "../../../routerPaths";
import { ClientSitePatchBody } from "../SiteEditPage";
import { SidebarContactForm } from "./SidebarContactForm";
import { SidebarFormPhotos } from "./SidebarFormPhotos";
import { SidebarFormServices } from "./SidebarFormServices";
import { SidebarNavbar } from "./SidebarNavbar";
import { SideFormHero } from "./SideFormHero";

export interface SidebarFormProps {
  form: ClientSitePatchBody & { id?: string };
  setForm: Dispatch<SetStateAction<ClientSitePatchBody | undefined>>;
}

export const SidebarForm: React.FC<SidebarFormProps> = (props) => {
  const i18n = useTranslations();
  const auth = useAuth();
  const api = useApi();
  const [loading, setLoading] = useState(false);
  const { siteId } = useParams<Parameters<typeof routerPaths.siteEdit>["0"]>();
  if (!siteId) throw new Error(`site id missing`);
  const handleSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();
    if (!props.form) return;
    setLoading(true);
    const results = await (
      await client.api.sites[":siteId"].$patch({
        param: { siteId },
        json: props.form,
        header: auth.header,
      })
    ).json();
    setLoading(false);
    if (results.success) {
      toast.success(i18n["Site updated"]);
      api.queryClient.invalidateQueries({
        queryKey: ["site", siteId],
      });
    } else {
      toast.error(i18n["Something went wrong"]);
    }
  };
  return (
    <form className="h-full border-e" onSubmit={handleSubmit}>
      <SidebarNavbar {...props} loading={loading} />
      <SideFormHero {...props} />
      <SidebarFormServices {...props} />
      <SidebarFormPhotos {...props} />
      <SidebarContactForm {...props} />
    </form>
  );
};
