import React from "react";

interface ToggleSwitchProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
}

export const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  label,
  checked = false,
  ...props
}) => {
  return (
    <label className="flex items-center space-x-2 cursor-pointer">
      <div className="relative inline-block w-[45px] h-[24px]">
        <input
          type="checkbox"
          className="peer absolute opacity-0 w-0 h-0"
          checked={checked}
          {...props}
        />
        <div className="w-full h-full bg-gray-300 rounded-full transition-colors duration-300 ease-in-out peer-checked:bg-primary"></div>
        <div className="absolute left-[4px] bottom-[4px] bg-white w-[16px] h-[16px] rounded-full shadow-md transition-transform duration-300 ease-in-out peer-checked:translate-x-[20px]"></div>
      </div>
      {label && <span className="select-none text-gray-700">{label}</span>}
    </label>
  );
};
