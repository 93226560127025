import React, { InputHTMLAttributes, useId } from "react";
import { Button, ButtonProps } from "./Button";

export interface InputProps
  extends InputHTMLAttributes<HTMLInputElement & HTMLTextAreaElement> {
  variant?: "outlined" | null;
  asTextarea?: boolean;
  rows?: number;
  inputClass?: string;
}

export const Input: React.FC<InputProps> = ({
  placeholder,
  id: _id,
  className,
  inputClass,
  asTextarea = false,
  rows = 4,
  ...props
}) => {
  const id = _id || useId();

  const baseClasses = [
    "block",
    "px-2.5",
    "pb-2.5",
    "pt-4",
    "w-full",
    "text-sm",
    "text-gray-900",
    "bg-transparent",
    "rounded-lg",
    "border",
    "border-gray-300",
    "appearance-none",
    "focus:outline-none",
    "focus:ring-2",
    "focus:ring-blue-600",
    "focus:border-blue-600",
    "peer",
  ].join(" ");

  return (
    <div className={["relative", className].join(" ")}>
      {asTextarea ? (
        <textarea
          {...props}
          id={id}
          rows={rows}
          className={[baseClasses, "resize-none", inputClass].join(" ")}
          placeholder=" "
        />
      ) : (
        <input
          id={id}
          type="text"
          placeholder=" "
          className={[baseClasses, inputClass].join(" ")}
          {...props}
        />
      )}

      <label
        htmlFor={id}
        className={[
          "absolute",
          "cursor-text",
          "text-sm",
          "text-gray-500",
          "duration-300",
          "transform",
          "-translate-y-4",
          "scale-75",
          "top-2",
          "left-2.5",
          "z-10",
          "origin-[0]",
          "bg-white",
          "px-1",
          "peer-placeholder-shown:translate-y-2",
          "peer-placeholder-shown:scale-100",
          "peer-focus:-translate-y-4",
          "peer-focus:scale-75",
          "peer-focus:text-blue-600",
        ].join(" ")}
      >
        {placeholder}
      </label>
    </div>
  );
};

export const InputWithButton: React.FC<{
  inputProps: InputProps;
  buttonProps: ButtonProps;
}> = (props) => {
  return (
    <div className="flex">
      <Input
        className="grow"
        inputClass="border-r-0 rounded-r-none"
        {...props.inputProps}
      />
      <Button
        {...props.buttonProps}
        className={`p-6 border-s-none rounded-s-none ${props.buttonProps.className}`}
      />
    </div>
  );
};
