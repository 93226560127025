export function stringToValidSubdomain(input: string): string {
  let result = input.toLowerCase();
  const accentsMap: { [key: string]: string } = {
    á: "a",
    à: "a",
    â: "a",
    ä: "a",
    ã: "a",
    å: "a",
    é: "e",
    è: "e",
    ê: "e",
    ë: "e",
    í: "i",
    ì: "i",
    î: "i",
    ï: "i",
    ó: "o",
    ò: "o",
    ô: "o",
    ö: "o",
    õ: "o",
    ú: "u",
    ù: "u",
    ü: "u",
    û: "u",
    ç: "c",
    ñ: "n",
  };

  // Replace accented characters
  result = result.replace(/([áàâäãåéèêëíìîïóòôöõúùüûçñ])/g, (match) => {
    return accentsMap[match as keyof typeof accentsMap] || match;
  });

  // Remove dots and other non-alphanumeric characters
  result = result.replace(/[^a-zA-Z0-9]/g, "");

  // Trim leading and trailing hyphens if any
  result = result.replace(/^[-_]+|[-_]+$/g, "");

  return result;
}
