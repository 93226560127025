import { InferRequestType, InferResponseType } from "hono/client";
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { ZodIssue } from "zod";
import { config } from "../config";
import { client } from "../helpers";
import { routerPaths } from "../pages/routerPaths";

export type SignInBody = InferRequestType<
  (typeof client.api)["users"]["sign-in"]["$post"]
>["json"];
export type SignUpBody = InferRequestType<
  (typeof client.api.users)["sign-up"]["$post"]
>["json"];

export interface AuthContextType {
  user:
    | (InferResponseType<(typeof client.api)["users"]["me"]["$get"]> & {
        success: true;
      })["data"]["user"]
    | null;
  isLoading: boolean;
  setUser: (user: AuthContextType["user"] | null) => void;
  token: string | null;
  header: { authorization: string };
  signIn(credentials: SignInBody): Promise<void>;
  signUp(credentials: SignUpBody): Promise<void>;
  signOut: () => void;
  reset: () => void;
  errors: ZodIssue[];
}

const AuthContext = createContext<AuthContextType>({
  user: null,
  setUser: () => {},
  token: null,
  header: { authorization: `` },
  isLoading: true,
  signIn: () => Promise.resolve(),
  signUp: () => Promise.resolve(),
  signOut: () => {},
  errors: [],
  reset: () => {},
});

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<AuthContextType["user"] | null>(null);
  const [errors, setErrors] = useState<ZodIssue[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSigningOut, setIsSigningOut] = useState(false);
  const [token, _setToken] = useState<string | null>(
    localStorage.getItem(config.localStorageKeys.token) || null
  );
  const setToken = (_token: string | null) => {
    if (_token) {
      localStorage.setItem(config.localStorageKeys.token, _token);
      _setToken(_token);
    } else {
      localStorage.removeItem(config.localStorageKeys.token);
    }
  };

  const reset = () => {
    setUser(null);
    setErrors([]);
    setIsLoading(false);
    setIsSigningOut(false);
  };

  const getMe = async () => {
    const _token = token || localStorage.getItem(config.localStorageKeys.token);
    if (!_token || user) {
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    const response = await (
      await client.api.users.me.$get({
        header: {
          authorization: `Bearer ${_token || ""}`,
        },
      })
    ).json();
    setIsLoading(false);
    if (response.success) {
      setUser(response.data.user);
    } else {
      setToken(null);
    }
  };

  const signUp = async (
    credentials: InferRequestType<
      (typeof client.api.users)["sign-up"]["$post"]
    >["json"]
  ) => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    const response = await (
      await client.api.users["sign-up"].$post({
        json: credentials,
      })
    ).json();
    setIsLoading(false);
    if (response.success) {
      setToken(response.data.token);
      getMe();
    } else {
      setErrors([...errors, ...response.error.issues]);
    }
  };

  const signIn = async (
    credentials: InferRequestType<
      (typeof client.api.users)["sign-in"]["$post"]
    >["json"]
  ) => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    const response = await (
      await client.api.users["sign-in"].$post({
        json: credentials,
      })
    ).json();
    setIsLoading(false);
    if (response.success) {
      setToken(response.data.token);
      getMe();
    } else {
      setErrors([...errors, ...response.error.issues]);
    }
  };

  const signOut = async () => {
    setToken(null);
  };

  useEffect(() => {
    getMe();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        token,
        isLoading,
        signIn,
        signUp,
        signOut,
        errors,
        reset,
        header: { authorization: `Bearer ${token}` },
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  return useContext(AuthContext);
};
