import { isEmpty, isUndefined } from "lodash";
import React from "react";
import { SaveIcon, PaletteIcon } from "lucide-react";
import { Link, Navigate, useLocation, useParams } from "react-router-dom";
import { useDocumentTitle } from "usehooks-ts";
import { Button, Loading } from "../../../components";
import { config } from "../../../config";
import { useApi, useTranslations } from "../../../contexts";
import { routerPaths } from "../../routerPaths";
import { AiTab } from "./AiTab";
import { BillingTab } from "./BillingTab";
import { DomainsTab } from "./DomainsTab";
import { GeneralTab } from "./GeneralTab";
import { SEOTab } from "./SEOTab";

export const SiteManagePage: React.FC = () => {
  const i18n = useTranslations();
  const { siteId, tab } =
    useParams<Parameters<typeof routerPaths.siteManage>[0]>();
  const location = useLocation();

  if (!siteId) {
    throw new Error(`siteId not found`);
  }
  const api = useApi();
  const site = api.siteById({ siteId });
  useDocumentTitle(`${config.app.name} - ${site?.data?.name}`);

  if (isUndefined(tab) || isEmpty(tab) || tab === "undefined") {
    return <Navigate to={routerPaths.siteManage({ siteId, tab: "general" })} />;
  }
  if (site?.isLoading) {
    return <Loading />;
  }
  if (!site?.data) {
    return <div>{i18n["Something went wrong"]}</div>;
  }
  return (
    <div>
      <h1 className="text-2xl font-bold flex gap-3 mb-8">
        <p className="grow">{site?.data?.name}</p>
        {site.data.hero?.image_uri && (
          <link rel="preload" as="image" href={site.data.hero.image_uri} />
        )}
        {site.data.photos?.items.map((item) => (
          <link key={item.uri} rel="preload" as="image" href={item.uri} />
        ))}
        <Button
          className="capitalize flex items-center gap-1"
          variant="secondary"
          linkTo={routerPaths.siteEdit({ siteId })}
          icon={<PaletteIcon />}
          label="designer"
        />
        <Button
          icon={<SaveIcon />}
          label="save"
          className="capitalize flex items-center gap-1"
        ></Button>
      </h1>
      <ul className="flex gap-4 border-b">
        {[
          [routerPaths.siteManage({ siteId, tab: "general" }), i18n["general"]],
          [routerPaths.siteManage({ siteId, tab: "domains" }), i18n["domains"]],
          [routerPaths.siteManage({ siteId, tab: "seo" }), i18n["seo"]],
          [routerPaths.siteManage({ siteId, tab: "ai" }), i18n["ai"]],
          [
            routerPaths.siteManage({ siteId, tab: "billings" }),
            i18n["billings"],
          ],
        ].map(([matchingRoute, title]) => (
          <li
            key={matchingRoute}
            className={`pb-hover:border-blue-400 pb-2 ${
              location.pathname === matchingRoute &&
              "border-b-2 border-blue-500 font-semibold"
            }`}
          >
            <Link to={matchingRoute}>{title}</Link>
          </li>
        ))}
      </ul>
      <div className="mt-4">
        {tab === "general" && <GeneralTab />}
        {tab === "domains" && <DomainsTab />}
        {tab === "seo" && <SEOTab />}
        {tab === "ai" && <AiTab />}
        {tab === "billings" && <BillingTab />}
      </div>
      <div className="mt-5"></div>
    </div>
  );
};
