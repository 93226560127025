import React from "react";

export interface SelectProps
  extends React.SelectHTMLAttributes<HTMLSelectElement> {
  placeholder?: string;
  options: Array<{ label: string; value: string }>;
}

export const Dropdown: React.FC<SelectProps> = ({
  id: _id,
  children,
  placeholder,
  ...props
}) => {
  const id = _id || `select-${Math.random().toString(36).substr(2, 9)}`;

  return (
    <div className="relative">
      <select
        id={id}
        className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-white rounded-lg border border-gray-300 appearance-none focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-blue-600 peer"
        {...props}
      >
        {props.options.map(({ label, value }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </select>
      <label
        htmlFor={id}
        className="absolute capitalize cursor-default text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 left-2.5 z-10 origin-[0] bg-white px-1 peer-placeholder-shown:translate-y-2 peer-placeholder-shown:scale-100 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:text-blue-600"
      >
        {placeholder}
      </label>
    </div>
  );
};
