import React from "react";
import type { RenderSiteProps } from "./RenderSite";
import { I18n } from "./i18n";

export const ContactForm: React.FC<RenderSiteProps> = (props) => {
  const i18n = I18n(props.site.language);
  if (!props.site?.contactForm) {
    return;
  }
  return (
    <section id="contact" className="py-16 bg-gray-50">
      <div className=" mx-auto px-6">
        <div className="text-center">
          {props.site.contactForm?.title && (
            <h2 className="text-4xl font-extrabold text-gray-800 mb-6">
              {props.site.contactForm?.title}
            </h2>
          )}
          {props.site.contactForm?.description && (
            <p className="text-lg text-gray-600 mb-8">
              {props.site.contactForm?.description}
            </p>
          )}
          {/* <div className="mt-8 flex justify-center  space-x-4">
            <a href="#" className="text-gray-600 " aria-label="Facebook">
              <svg className="w-8 h-8" fill="currentColor" viewBox="0 0 24 24">
                <path d="M22.675 0h-21.35C0.595 0 0 0.6 0 1.333v21.333C0 23.4 0.595 24 1.325 24H12.82v-9.293H9.692v-3.622h3.128V8.413c0-3.1 1.903-4.788 4.679-4.788 1.329 0 2.474.1 2.805.144v3.246l-1.924.001c-1.507 0-1.798.716-1.798 1.764v2.314h3.588l-.467 3.622h-3.121V24h6.116c.73 0 1.325-.6 1.325-1.334V1.333C24 0.6 23.405 0 22.675 0z"></path>
              </svg>
            </a>
            <a href="#" className="text-gray-600 " aria-label="Twitter">
              <svg className="w-8 h-8" fill="currentColor" viewBox="0 0 24 24">
                <path d="M24 4.557a9.839 9.839 0 01-2.825.775 4.927 4.927 0 002.165-2.724 9.865 9.865 0 01-3.127 1.195 4.916 4.916 0 00-8.385 4.482A13.944 13.944 0 011.671 3.149a4.911 4.911 0 001.524 6.555 4.902 4.902 0 01-2.229-.616c-.054 2.281 1.581 4.415 3.95 4.89a4.932 4.932 0 01-2.224.084 4.919 4.919 0 004.593 3.417 9.865 9.865 0 01-6.102 2.105c-.396 0-.787-.023-1.175-.069A13.933 13.933 0 007.548 21c9.057 0 14.01-7.506 14.01-14.01 0-.213-.005-.426-.015-.637A10.012 10.012 0 0024 4.557z"></path>
              </svg>
            </a>
            <a href="#" className="text-gray-600 " aria-label="LinkedIn">
              <svg className="w-8 h-8" fill="currentColor" viewBox="0 0 24 24">
                <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.761 0 5-2.239 5-5v-14c0-2.761-2.239-5-5-5zm-11.292 19h-2.756v-8.711h2.756v8.711zm-1.378-9.955c-.88 0-1.591-.711-1.591-1.591s.711-1.591 1.591-1.591 1.591.711 1.591 1.591-.711 1.591-1.591 1.591zm12.67 9.955h-2.754v-4.465c0-1.065-.019-2.438-1.484-2.438-1.486 0-1.713 1.161-1.713 2.357v4.546h-2.754v-8.711h2.646v1.189h.037c.368-.694 1.27-1.428 2.616-1.428 2.796 0 3.31 1.841 3.31 4.234v4.716z"></path>
              </svg>
            </a>
          </div> */}
        </div>
        <form className="space-y-6 grow max-w-xl mx-auto bg-white p-8 rounded-lg shadow-lg mt-5">
          <div>
            <input
              type="email"
              id="email"
              name="email"
              placeholder={i18n["Your email address"]}
              className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
              required
            />
          </div>
          <div>
            <textarea
              id="message"
              name="message"
              rows={8}
              placeholder={i18n["Your message"]}
              className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent resize-none"
            ></textarea>
          </div>
          <div className="text-right">
            <button
              type="submit"
              className="bg-green-500 text-white py-3 px-8 rounded-full shadow-lg hover:bg-primary-dark transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
            >
              {i18n["Send"]}
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};
