import React from "react";
import { SidebarFormProps } from "./SidebarForm";
import { Input, ToggleSwitch } from "../../../../components";
import { useTranslations } from "../../../../contexts";

interface SidebarContactFormProps extends SidebarFormProps {}

export const SidebarContactForm: React.FC<SidebarContactFormProps> = (
  props
) => {
  const i18n = useTranslations();

  return (
    <div id="contact-form" className="p-6">
      <div className="flex ">
        <h2 className="grow font-bold pb-4 capitalize text-xl">
          {i18n["contactForm"]}
        </h2>
        <ToggleSwitch
          checked={props.form.contactForm?.is_enabled}
          onChange={() =>
            props.setForm({
              ...props.form,
              contactForm: {
                ...props.form.contactForm,
                is_enabled: !props.form.contactForm?.is_enabled,
              },
            })
          }
        />
      </div>
      {props.form.contactForm?.is_enabled && (
        <div>
          <Input
            className="mb-2"
            placeholder={i18n["title"]}
            value={props.form.contactForm.title || undefined}
            onChange={(event) =>
              props.setForm({
                ...props.form,
                contactForm: {
                  ...props.form.contactForm,
                  title: event.target.value,
                },
              })
            }
          />
          <Input
            variant="outlined"
            placeholder="Description"
            value={props.form.contactForm.description || undefined}
            onChange={(event) =>
              props.setForm({
                ...props.form,
                contactForm: {
                  ...props.form.contactForm,
                  description: event.target.value,
                },
              })
            }
          />
        </div>
      )}
    </div>
  );
};
