import React, { FC, PropsWithChildren } from "react";
import { createBrowserRouter, Navigate } from "react-router-dom";
import { Loading } from "../components";
import { useAuth } from "../contexts";
import { DashboardPage } from "./DashboardPage";
import { DashboardLayout } from "./layouts/DashboardLayout";
import { RootLayout } from "./layouts/RootLayout";
import { routerPaths } from "./routerPaths";
import { SignInPage } from "./SignInPage";
import { SignUpPage } from "./SignUpPage";
import { SiteEditPage, SiteManagePage, SiteNewPage } from "./Sites";
import { SettingsPage } from "./SettingsPage";

const PrivateRoute: FC<PropsWithChildren> = ({ children }) => {
  const { user, isLoading } = useAuth();
  if (isLoading) {
    return <Loading />;
  }
  if (!user) {
    console.info("redirect to sign up because no user");
    return <Navigate to={routerPaths.signUp} />;
  }
  return children;
};

export const router = createBrowserRouter(
  [
    {
      path: "",
      element: <Navigate to="/dashboard" replace />,
    },
    { path: routerPaths.signIn, element: <SignInPage /> },
    { path: routerPaths.signUp, element: <SignUpPage /> },
    {
      element: <RootLayout />,
      children: [
        {
          path: "",
          element: (
            <PrivateRoute>
              <DashboardLayout />
            </PrivateRoute>
          ),
          children: [
            {
              path: routerPaths.dashboard,
              element: <DashboardPage />,
            },
            {
              path: routerPaths.siteManage({ siteId: ":siteId", tab: ":tab" }),
              element: <SiteManagePage />,
            },
            {
              path: routerPaths.siteNew,
              element: <SiteNewPage />,
            },
            {
              path: routerPaths.settings,
              element: <SettingsPage />,
            },
          ],
        },
        {
          path: routerPaths.siteEdit({ siteId: ":siteId" }),
          element: (
            <PrivateRoute>
              <SiteEditPage />
            </PrivateRoute>
          ),
        },
      ],
    },
  ],
  {
    future: {
      v7_relativeSplatPath: true,
      v7_fetcherPersist: true,
      v7_skipActionErrorRevalidation: true,
      v7_partialHydration: true,
      v7_normalizeFormMethod: true,
    },
  }
);
