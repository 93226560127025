import React, { FC, PropsWithChildren } from "react";
import { Outlet } from "react-router-dom";
import { AppNavbar } from "../../components";

export const DashboardLayout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div>
      <AppNavbar />
      <div className="max-w-screen-md m-auto">
        <Outlet />
      </div>
    </div>
  );
};
