import React, { FC } from "react";
import { Outlet } from "react-router-dom";

export const RootLayout: FC = () => (
  <>
    <main className="h-full">
      <Outlet />
    </main>
  </>
);
