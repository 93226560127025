import React, { FC } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { RouterProvider } from "react-router-dom";
import { router } from "./pages/router";
import { ApiProvider, AuthProvider, TranslationProvider } from "./contexts";

import { ToastContainer } from "react-toastify";

const queryClient = new QueryClient();

export const App: FC = () => (
  <QueryClientProvider client={queryClient}>
    <AuthProvider>
      <ApiProvider>
        <TranslationProvider>
          <RouterProvider
            router={router}
            future={{
              v7_startTransition: true,
            }}
          />
          <ToastContainer />
        </TranslationProvider>
      </ApiProvider>
    </AuthProvider>
  </QueryClientProvider>
);

export default App;
