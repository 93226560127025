import { AppType } from "@server/src/type";
import { hc } from "hono/client";
import { config } from "./config";
import { useCallback } from "react";
import { throttle } from "lodash";

export const client = hc<AppType>(config.apiUrl);
export const useThrottle = <T extends any>(
  f: (...args: T[]) => void,
  delay: number
) => useCallback(throttle(f, delay), [delay]);
