import React, { FormEventHandler, useState } from "react";
import { Button, Dropdown } from "../components";
import { toast } from "react-toastify";
import { Save } from "lucide-react";
import { client } from "../helpers";
import { useAuth, useTranslations } from "../contexts";
import { InferRequestType, InferResponseType } from "hono/client";

type ClientUserPatch = (typeof client.api)["users"]["me"]["$patch"];
type ClientSitePatchRequest = InferRequestType<ClientUserPatch>["json"];

const DangerZone: React.FC = () => {
  const auth = useAuth();
  const i18n = useTranslations();
  const deleteUser = async () => {
    const response = await client.api.users.$delete({
      header: { authorization: `Bearer ${auth.token}` },
    });
    if (response.status === 200) {
      window.alert(i18n["Your account has been deleted"]);
      window.location.reload();
    }
  };
  return (
    <div className="mt-8 p-3   border rounded border-red-600">
      <h2 className="text-xl text-red-600 font-bold mb-4 capitalize">
        {i18n.dangerZone}
      </h2>
      <Button
        id="delete-account-btn"
        className="capitalize"
        variant="destructive"
        onClick={(event) => {
          if (
            window.prompt(
              i18n[
                "Are you sure you want to delete this site? To confirm, type DELETE."
              ]
            ) === i18n.confirmDeleteWord
          ) {
            deleteUser();
          } else {
            event.preventDefault();
          }
        }}
      >
        {i18n["delete your account"]}
      </Button>
      <p className="mt-2 text-gray-600">
        {i18n["This action cannot be reverted or undone"]}
      </p>
    </div>
  );
};

export const Language: React.FC<{
  onChange: (params: Pick<ClientSitePatchRequest, "language">) => void;
}> = (props) => {
  const i18n = useTranslations();
  const auth = useAuth();
  return (
    <div>
      <h2 className="text-xl font-semibold mb-4">{i18n.general}</h2>
      <Dropdown
        options={[
          { label: "🇬🇧 English", value: "en" },
          { label: "🇫🇷 French", value: "fr" },
        ]}
        placeholder={i18n.language}
        defaultValue={auth.user?.language}
        onChange={(event) => {
          i18n.setLanguage(event.target.value);
          props.onChange({ language: event.target.value });
        }}
      />
    </div>
  );
};

export const SettingsPage: React.FC = () => {
  const i18n = useTranslations();
  const auth = useAuth();
  const [form, setForm] = useState<ClientSitePatchRequest>();
  const [loading, setLoading] = useState(false);
  const handleSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();
    if (!form) return;
    setLoading(true);
    const results = await (
      await client.api.users.me.$patch({
        header: { authorization: `Bearer ${auth.token}` },
        json: form,
      })
    ).json();
    if (results.success) {
      toast.success(i18n["Your settings have been updated"]);
      auth.setUser(results.data.user);
    } else {
      toast.error(i18n["Something went wrong"]);
    }
    setLoading(false);
  };
  return (
    <div id="settings-page" className="max-w-screen-md max-md:p-5 m-auto">
      <form onSubmit={handleSubmit}>
        <h1 className="text-2xl font-bold mb-6 flex">
          <p className="grow capitalize">{i18n.settings}</p>

          <Button loading={loading} type="submit">
            <div className="flex items-center gap-1 capitalize">
              <Save className="mr-3 h-4 w-4" />
              {i18n.save}
            </div>
          </Button>
        </h1>
        <Language onChange={(params) => setForm({ ...form, ...params })} />
        <DangerZone />
      </form>
    </div>
  );
};
