import React from "react";
import { ImageUpload, Input } from "../../../../components";
import { useTranslations } from "../../../../contexts";
import type { SidebarFormProps } from "./SidebarForm";

export const SideFormHero: React.FC<SidebarFormProps> = (props) => {
  const i18n = useTranslations();
  return (
    <div id="hero-form" className="border-b p-6">
      <div className="pb-4">
        <h2 className="font-bold pb-2 text-xl grow">{i18n["logo"]}</h2>
        <ImageUpload
          className="max-w-[100px]"
          image={
            props.form.hero?.image_uri &&
            `${props.form.hero?.image_uri}?site_id=${props.form.id}`
          }
          onChange={({ uri }) =>
            props.setForm({
              ...props.form,
              hero: {
                ...props.form.hero,
                image_uri: uri,
              },
            })
          }
          onDelete={() =>
            props.setForm({
              ...props.form,
              hero: { ...props.form.hero, image_uri: null },
            })
          }
        />
      </div>
      <h2 className="font-bold pb-4 text-xl">{i18n["introduction"]}</h2>
      <Input
        className="pb-2"
        placeholder={i18n["title"]}
        value={props.form.hero?.title || ""}
        onChange={(event) =>
          props.setForm({
            ...props.form,
            hero: { ...props.form.hero, title: event.target.value },
          })
        }
      />
      <Input
        asTextarea
        className="pb-2"
        placeholder={i18n["description"]}
        value={props.form.hero?.description || ""}
        onChange={(event) =>
          props.setForm({
            ...props.form,
            hero: { ...props.form.hero, description: event.target.value },
          })
        }
      />

      {/* <h2 className="font-bold pb-2 pt-2 text-l">{i18n["button"]}</h2>
      <Input
        className="mb-2"
        placeholder={i18n["Main button"]}
        onChange={(event) =>
          props.setForm({
            ...props.form,
            hero: { ...props.form.hero, cta: event.target.value },
          })
        }
      />
      <Input
        placeholder={i18n["link"]}
        onChange={(event) =>
          props.setForm({
            ...props.form,
            hero: { ...props.form.hero, cta_link: event.target.value },
          })
        }
      /> */}
    </div>
  );
};
