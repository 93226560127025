const staging = {};

export const config = {
  app: { name: "Zuv" },
  localStorageKeys: {
    token: `zuv:authToken`,
  },
  apiUrl:
    {
      localhost: "http://localhost:8787",
      "staging.zuv.app": "https://zuv-api-staging.rough-base-4af9.workers.dev/",
      "zuv.app": "https://zuv-api-production.rough-base-4af9.workers.dev",
    }[window.location.hostname] || "http://localhost:8787",
};
