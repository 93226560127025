import React, {
  ChangeEventHandler,
  FormEventHandler,
  useCallback,
  useState,
} from "react";
import { useDocumentTitle, useDebounceCallback } from "usehooks-ts";
import { useAuth, useTranslations } from "../../../contexts";
import { config } from "../../../config";
import { Button, Input, Loading } from "../../../components";
import { Save, Check, Loader, XIcon } from "lucide-react";
import { client, useThrottle } from "../../../helpers";
import { InferRequestType } from "hono";
import { useNavigate } from "react-router";
import { routerPaths } from "../../routerPaths";
import { toast } from "react-toastify";
import { stringToValidSubdomain } from "../../../../../server/src/helpers/stringToValidSubdomain";
import { throttle } from "lodash";

type ClientSitePut = (typeof client.api)["sites"]["$post"];
type ClientSitePutRequest = InferRequestType<ClientSitePut>["json"];

export const SiteNewPage: React.FC = () => {
  const auth = useAuth();
  const header = { authorization: `Bearer ${auth.token}` };
  const i18n = useTranslations();
  const navigate = useNavigate();
  useDocumentTitle(`${config.app.name} - ${i18n["create a new site"]}`);
  const [form, setForm] = useState<ClientSitePutRequest>();
  const [siteExists, setSiteExists] = useState({
    loading: false,
    exists: false,
  });
  const handleSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();
    if (!form) {
      return;
    }
    const results = await (
      await client.api.sites.$post({
        header,
        json: form,
      })
    ).json();
    if (results.success) {
      navigate(routerPaths.siteEdit({ siteId: results.data.site.id }));
    } else {
      toast.error(i18n["Something went wrong"]);
    }
  };
  const checkIfExists = useCallback(
    throttle(async (name: string) => {
      if (!name) {
        setSiteExists({ loading: false, exists: false });
        return;
      }
      setSiteExists({ exists: false, loading: true });
      const results = await (
        await client.api.sites[":slug"].exits.$get({
          header,
          param: { slug: stringToValidSubdomain(name) },
        })
      ).json();
      if (results.success) {
        setSiteExists({ exists: results.data.site.exists, loading: false });
      } else {
        setSiteExists({ exists: false, loading: false });
        toast.error(i18n["Something went wrong"]);
      }
    }, 1000),
    []
  );
  const onNameChange: ChangeEventHandler<HTMLInputElement> = async (event) => {
    const name = event.target.value;
    setForm({ ...form, name });
    setSiteExists({ loading: true, exists: false });
    checkIfExists(name);
  };

  return (
    <div id="site-new-page">
      <form onSubmit={handleSubmit}>
        <h1 className="text-2xl font-bold border-b p-3 mb-6 flex">
          <p className="grow capitalize">{i18n["create a new site"]}</p>

          <Button>
            <div className="flex items-center gap-1 capitalize">
              <Save className="mr-3 h-4 w-4" />
              {i18n.save}
            </div>
          </Button>
        </h1>
        <Input onChange={onNameChange} placeholder={i18n["name"]} />
        {form?.name && (
          <div className="font-bold mt-3">
            {!siteExists.exists && !siteExists.loading && (
              <p className="text-success flex">
                <Check className="me-2 " />
                {stringToValidSubdomain(form?.name)}.zuv.fr{" "}
                {i18n["is available"]}
              </p>
            )}
            {siteExists.loading && (
              <p>
                <Loading />
              </p>
            )}
            {siteExists.exists && !siteExists.loading && (
              <p className="text-destructive flex">
                <XIcon className="me-2" />
                {stringToValidSubdomain(form?.name)}.zuv.fr{" "}
                {i18n["is already taken"]}
              </p>
            )}
          </div>
        )}
      </form>
    </div>
  );
};
