import type { InferRequestType } from "hono/client";
import { defaultsDeep } from "lodash";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { RenderSite } from "../../../../../server/src/render/RenderSite";
import { useApi, useTranslations } from "../../../contexts";
import { client } from "../../../helpers";
import { routerPaths } from "../../routerPaths";
import { SidebarForm } from "./SidebarForm";
import { Site } from "../../../types";
import { Loading } from "../../../components";

export type ClientSitePatch = (typeof client.api)["sites"][":siteId"]["$get"];
export type ClientSitePatchBody = InferRequestType<
  (typeof client.api)["sites"][":siteId"]["$patch"]
>["json"];

export const SiteEditPage: React.FC = () => {
  const { siteId } = useParams<Parameters<typeof routerPaths.siteEdit>["0"]>();
  if (!siteId) {
    throw new Error(`siteId is undefined`);
  }
  const [form, setForm] = useState<ClientSitePatchBody>();

  const api = useApi();
  const i18n = useTranslations();
  const site = api.siteById({ siteId })?.data;

  useEffect(() => {
    setForm(
      defaultsDeep(
        {
          hero: {
            title: site?.name,
            cta: i18n["Contact us"],
            cta_link: "#contact",
          },
          services: {
            title: i18n["Our services"],
          },
          contactForm: {
            title: i18n["Still have questions? Get in touch."],
          },
        } as Partial<Site>,
        site
      )
    );
  }, [site]);
  if (!site) {
    return <div>Could not find site {siteId}</div>;
  }

  if (!form) {
    return <Loading />;
  }
  return (
    <div className="flex h-full overflow-hidden">
      <div className="h-full border-e overflow-y-scroll w-[500px]">
        <SidebarForm form={form} setForm={setForm} />
      </div>
      <div className="grow h-full w-full overflow-y-scroll">
        <RenderSite
          site={{
            ...site,
            ...form,
            hero: {
              ...site.hero,
              ...form.hero,
              image_uri:
                form.hero?.image_uri &&
                `${form.hero?.image_uri}?site_id=${site.id}`,
            },
            services: {
              ...site.services,
              ...form.services,
              items: form.services?.items,
            },
            photos: {
              ...site.photos,
              ...form.photos,
              items:
                form.photos?.items?.map(({ uri, ...item }) => ({
                  created_at: "",
                  updated_at: "",
                  is_enabled: 1,
                  photo_id: "",
                  id: "",
                  alt: "wip",
                  ...item,
                  uri: `${uri}?site_id=${site.id}`,
                })) || [],
            },
          }}
        />
      </div>
    </div>
  );
};
