import React, { ReactElement, ReactNode, RefAttributes } from "react";

import { type VariantProps, cva } from "class-variance-authority";
import { LoaderCircle } from "lucide-react";
import { cn } from "../tailwind-utils";
import { Link } from "react-router-dom";
import { en } from "../contexts/i18n/en";
import { useTranslations } from "../contexts";

const buttonVariants = cva(
  "inline-flex cursor-pointer items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        default: "bg-primary text-primary-foreground hover:bg-primary/90",
        destructive:
          "bg-destructive text-destructive-foreground hover:bg-destructive/90",
        success: "bg-success",
        outline:
          "border border-solid border-input bg-background hover:bg-accent hover:text-accent-foreground",
        secondary:
          "bg-secondary text-secondary-foreground hover:bg-secondary/80",
        ghost: "hover:bg-accent hover:text-accent-foreground",
        link: "text-primary underline-offset-4 hover:underline",
      },
      size: {
        default: "h-10 px-4 py-2",
        sm: "h-9 rounded-md px-3",
        lg: "h-11 rounded-md px-8",
        icon: "h-10 w-10",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  loading?: boolean;
  as?: React.ElementType;
  ref?: React.Ref<HTMLButtonElement>;
  icon?: ReactElement;
  label?: keyof typeof en;
  linkTo?: string;
}

const Button: React.FC<ButtonProps> = ({
  className,
  variant,
  size,
  loading,
  linkTo,
  label,
  children,
  icon,
  ..._props
}) => {
  const i18n = useTranslations();
  const Comp = linkTo ? Link : ("button" as React.ElementType);
  const props = {
    className: cn(buttonVariants({ variant, size, className })),
    ..._props,
    disabled: loading || _props.disabled,
    to: linkTo || undefined,
  };

  return (
    <Comp {...props}>
      {(icon || loading) && (
        <span
          className={`h-4 w-4 inline-flex items-center justify-center ${
            loading && "animate-spin"
          } ${label && "mr-2"}`}
        >
          {loading ? <LoaderCircle /> : icon}
        </span>
      )}
      {label && i18n[label]}
      {children}
    </Comp>
  );
};
Button.displayName = "Button";

export { Button, buttonVariants };
