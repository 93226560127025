import React from "react";
import type { RenderSiteProps } from "./RenderSite";

export const Hero: React.FC<RenderSiteProps> = (props) => {
  return (
    <section
      id="hero"
      className="min-h-[90vh] flex flex-col items-center justify-center bg-gradient-to-b from-gray-900 to-gray-800 text-center px-6 py-20"
    >
      {props.site.hero?.image_uri && (
        <div className="relative py-8 mx-auto max-w-2xl  overflow-hidden">
          <img
            src={props.site.hero.image_uri}
            alt="Hero Image"
            className="w-full rounded-lg"
          />
        </div>
      )}
      <div className="max-w-4xl mx-auto magictime vanishIn text-white p-8 rounded">
        <h1 className="text-5xl md:text-7xl font-extrabold mb-6 logo">
          {props.site.hero?.title}
        </h1>
        {props.site.hero?.description && (
          <p className="text-lg md:text-2xl mb-8">
            {props.site.hero.description.split("\n").map((line, index) => (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </p>
        )}
        {props.site.hero?.cta && (
          <div className="flex justify-center space-x-4 mb-12">
            <a
              href={props.site.hero?.cta_link || "#"}
              className="bg-sky-500 text-white py-3 px-8 rounded-full shadow-lg text-lg md:text-xl hover:bg-green-700 transition duration-300"
            >
              {props.site.hero?.cta}
            </a>
          </div>
        )}
      </div>
    </section>
  );
};
