import React from "react";
import { useFieldArray } from "react-hook-form";
import type { SidebarFormProps } from "./SidebarForm";
import { Input, ToggleSwitch } from "../../../../components";
import { useTranslations } from "../../../../contexts";
import { isEmpty } from "lodash";

export const SidebarFormServices: React.FC<SidebarFormProps> = (props) => {
  const i18n = useTranslations();

  return (
    <div className="border-b p-6">
      <div className="flex ">
        <h2 className="grow font-bold pb-4  text-xl">Services</h2>
        <ToggleSwitch
          checked={props.form.services?.is_enabled}
          onChange={() =>
            props.setForm({
              ...props.form,
              services: {
                ...props.form.services,
                is_enabled: !props.form.services?.is_enabled,
              },
            })
          }
        />
      </div>
      {props.form.services?.is_enabled && (
        <div>
          <div className="pb-2">
            <Input
              variant="outlined"
              placeholder={i18n["title"]}
              value={props.form.services.title || ""}
              onChange={(event) => {
                props.setForm({
                  ...props.form,
                  services: {
                    ...props.form.services,
                    title: event.target.value,
                  },
                });
              }}
            />
          </div>
          {[
            ...(props.form.services?.items || []),
            { id: crypto.randomUUID() },
          ]?.map((item, index) => (
            <div className="pt-2" key={item.id}>
              <Input
                className="mb-2"
                variant="outlined"
                placeholder={i18n["title"]}
                value={item.title || ""}
                onChange={(event) => {
                  if (
                    isEmpty(event.target.value) &&
                    isEmpty(item.description)
                  ) {
                    props.setForm({
                      ...props.form,
                      services: {
                        ...props.form.services,
                        items: props.form.services?.items?.filter(
                          (i, index2) => index2 !== index
                        ),
                      },
                    });
                    return;
                  }
                  props.setForm({
                    ...props.form,
                    services: {
                      ...props.form.services,
                      items: props.form.services?.items
                        ?.slice(0, index)
                        .concat([
                          {
                            ...item,
                            title: event.target.value,
                          },
                        ])
                        .concat(props.form.services?.items?.slice(index + 1)),
                    },
                  });
                }}
              />
              <Input
                variant="outlined"
                placeholder="Description"
                asTextarea
                value={item.description || ""}
                onChange={(event) => {
                  if (isEmpty(event.target.value) && isEmpty(item.title)) {
                    props.setForm({
                      ...props.form,
                      services: {
                        ...props.form.services,
                        items: props.form.services?.items?.filter(
                          (i, index2) => index2 !== index
                        ),
                      },
                    });
                    return;
                  }
                  props.setForm({
                    ...props.form,
                    services: {
                      ...props.form.services,
                      items: props.form.services?.items
                        ?.slice(0, index)
                        .concat([
                          {
                            ...item,
                            description: event.target.value,
                          },
                        ])
                        .concat(props.form.services?.items?.slice(index + 1)),
                    },
                  });
                }}
              />
            </div>
          ))}
          {/* <div className="pt-2" key={""}>
            <Input
              className="mb-2"
              variant="outlined"
              placeholder={i18n["title"]}
              value={""}
            />
            <Input variant="outlined" placeholder="Description" value={""} />
          </div> */}
        </div>
      )}
    </div>
  );
};
