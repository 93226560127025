import { MenuIcon, ChevronDown } from "lucide-react";
import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAuth, useTranslations } from "../contexts";
import { routerPaths } from "../pages/routerPaths";
import { getI18n } from "../contexts/i18n";

const NavbarItems: React.FC = () => {
  const location = useLocation();
  const i18n = useTranslations();
  const navbarOptions: Array<{ label: string; href: string }> = [
    {
      label: i18n.sites,
      href: routerPaths.dashboard,
    },
    {
      label: i18n.settings,
      href: routerPaths.settings,
    },
  ];
  return (
    <>
      {navbarOptions.map(({ label, href }) => (
        <Link
          key={encodeURI(label + href)}
          className={`block rounded-md ${
            location.pathname === href && "bg-gray-900"
          } px-3 py-2 text-sm font-medium text-white capitalize`}
          to={href}
        >
          {label}
        </Link>
      ))}
    </>
  );
};

export const ProfileDropdown: React.FC = () => {
  const auth = useAuth();
  const i18n = getI18n(auth.user?.language);
  return (
    <div
      className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 ring-1 shadow-lg ring-black/5 focus:outline-hidden"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="user-menu-button"
      tabIndex={-1}
    >
      <button
        onClick={() => {
          auth.signOut();
        }}
        className="block px-4 py-2 text-sm text-red-700"
        role="menuitem"
        tabIndex={-1}
        id="user-menu-item-2"
      >
        {i18n["Sign Out"]}
      </button>
    </div>
  );
};

export const ProfileButton: React.FC = () => {
  const [profileMenuOpen, setProfileMenuOpen] = useState<boolean>(false);
  const ref = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const onClick = ({ target }: any) =>
      !ref?.current?.contains(target) && setProfileMenuOpen(false);
    document.addEventListener("click", onClick);
    return () => document.removeEventListener("click", onClick);
  }, []);
  const auth = useAuth();
  return (
    <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
      <div className="relative ml-3">
        <div>
          <button
            ref={ref}
            onClick={() => setProfileMenuOpen(!profileMenuOpen)}
            className="flex items-center rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
          >
            <span className="sr-only">Open user menu</span>
            {auth.user?.email}
            <ChevronDown className="ms-2" />
          </button>
        </div>
        {profileMenuOpen && <ProfileDropdown />}
      </div>
    </div>
  );
};

export const AppNavbar: React.FC = () => {
  const [mainMenuOpen, setMainMenuOpen] = useState<boolean>(false);

  return (
    <nav className="bg-gray-800 mb-12">
      <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
        <div className="relative flex h-16 items-center justify-between">
          <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
            {/* <!-- Mobile menu button--> */}
            <button
              onClick={() => setMainMenuOpen(!mainMenuOpen)}
              type="button"
              className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:ring-2 focus:ring-white focus:outline-hidden focus:ring-inset"
              aria-controls="mobile-menu"
              aria-expanded="false"
            >
              <span className="absolute -inset-0.5"></span>
              <span className="sr-only">Open main menu</span>
              <MenuIcon />
            </button>
          </div>
          <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
            <div className="hidden sm:ml-6 sm:block">
              <div className="flex space-x-4">
                <NavbarItems />
              </div>
            </div>
          </div>
          <ProfileButton />
        </div>
      </div>
      {mainMenuOpen && (
        <div className="sm:hidden" id="mobile-menu">
          <div className="space-y-1 px-2 pt-2 pb-3">
            <NavbarItems />
          </div>
        </div>
      )}
    </nav>
  );
};
