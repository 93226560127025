import { en } from "./en";

const confirmDeleteWord = "SUPPRIMER";

export const fr = {
  sites: "sites",
  settings: "préferences",
  save: "sauvegarder",
  add: "Ajouter",
  delete: "Supprimer",
  cancel: "annuler",
  dashboard: "Tableau de bord",
  "create a new site": "Créer un nouveau site",
  name: "Nom",
  edit: "modifer",
  new: "Créer",
  "Sign Out": "Se déconnecter",
  signIn: "Se connecter",
  signUp: "S'inscrire",
  designer: "Designer",
  language: "Langue",
  "is already taken": "est déjà pris",
  "is available": "est disponible",
  "Your settings have been updated": "Vos paramètres ont été mis à jour",
  "Your email": "Votre email",
  "Forgotten password?": "Mot de passe oublié ?",
  "Don't have an account yet?": "Pas encore de compte ?",
  "Already have an account?": "Vous avez déjà un compte ?",
  title: "Titre",
  description: "Description",
  button: "Bouton",
  logo: "Logo",
  introduction: "Introduction",
  "Main button": "Bouton principal",
  link: "Lien",
  "Site updated": "Site mis à jour",
  "Contact us": "Contactez-nous",
  "Still have questions? Get in touch.": "Une question ? Écrivez-nous.",
  "Our services": "Nos services",
  username: "Nom d'utilisateur",
  password: "Mot de passe",
  contactForm: "formulaire de contact",
  dangerZone: "zone à risque",
  "This action cannot be reverted or undone": [
    "Cette action ne peut pas être annulée ou rétablie.",
    "Une fois supprimé, toutes les données",
    "seront définitivement supprimées et ne pourront pas être récupérées",
  ].join(" "),
  "Are you sure?": "Êtes-vous sûr ?",
  "delete your account": "Supprimer votre compte",
  confirmDeleteWord,
  "Are you sure you want to delete this site? To confirm, type DELETE.": [
    `Êtes-vous sûr de vouloir supprimer votre compte ?`,
    `Pour confirmer, tapez '${confirmDeleteWord}'.`,
    `Cette action ne peut pas être annulée.`,
  ].join(" "),
  "Your account has been deleted": "Votre compte a été supprimé.",
  "Something went wrong": "Désolé, une erreur est survenue !",
  general: "Général",
  domains: "Noms de domaine",
  "Domain deleted": "Domain supprimé",
  seo: "Référencement",
  ai: "IA",
  billings: "Facturations",
  "Keywords describe your content and help search engines categorize it": [
    `Les mots-clés décrivent votre contenu et aident les moteurs de recherche à le catégoriser.`,
    `Cependant, parce que trop de gens en abusent,`,
    `les moteurs de recherche donnent maintenant la priorité à la façon dont votre contenu répond aux questions des utilisateurs.`,
    `Utilisez les mots-clés de manière naturelle, mais ne les forcez pas.`,
    `Au lieu de cela, concentrez-vous sur la création d'un contenu clair, pertinent et détaillé.`,
  ].join(" "),
  "A domain is the unique web address (e.g. yourwebsite.com) that people use to access your site.":
    "Un domaine est l'adresse web unique (par exemple, votresiteweb.com) que les gens utilisent pour accéder à votre site.",
  "You will need to set up TWO CNAME records with your domain registrar":
    "Vous devrez configuer DEUX CNAME avec votre registraire de domaine",
  "Here are the links to the documentation pages for various popular registrars":
    "Voici les liens vers les pages de documentation des bureaux d'enregistrement les plus populaires",
  "If you encounter any difficulties, please contact us":
    "Si vous rencontrez des difficultés, contacter nous",
  "CNAME doc links - OVH":
    "https://help.ovhcloud.com/csm/fr-email-cname-record?id=kb_article_view&sysparm_article=KB0053252",
  "CNAME doc links - Ionos":
    "https://www.ionos.fr/assistance/domaines/configurer-des-enregistrements-cname-pour-des-sous-domaines/configurer-un-enregistrement-cname-pour-un-sous-domaine-existant/",
} satisfies { [K in keyof typeof en]: string };
