import React from "react";

import { ArrowLeft, Save } from "lucide-react";

import { Button } from "../../../../components";
import { useTranslations } from "../../../../contexts";
import { routerPaths } from "../../../routerPaths";
import { SidebarFormProps } from "./SidebarForm";

interface SidebarNavbarProps extends SidebarFormProps {
  loading: boolean;
}

export const SidebarNavbar: React.FC<SidebarNavbarProps> = (props) => {
  const i18n = useTranslations();
  return (
    <div className="sticky bg-white top-0 z-50 w-full border-b">
      <div
        id="sidebar-form-actions-navbar"
        className="flex justify-between p-2"
      >
        <Button
          variant="destructive"
          linkTo={routerPaths.siteManage({ siteId: props.form.id || "" })}
        >
          <div className="flex items-center gap-1">
            <ArrowLeft className="mr-3 h-4 w-4" />
            <span className="capitalize">{i18n.cancel}</span>
          </div>
        </Button>

        <Button className="flex" type="submit" loading={props.loading}>
          <div className="flex items-center gap-1">
            <Save className="mr-3 h-4 w-4" />
            <span className="capitalize">{i18n.save}</span>
          </div>
        </Button>
      </div>
    </div>
  );
};
