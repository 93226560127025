import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { InferResponseType } from "hono/client";
import React, { createContext, PropsWithChildren, useContext } from "react";
import { client } from "../helpers";
import { useAuth } from "./AuthContext";
import { useQueryClient } from "@tanstack/react-query";
import { QueryClient } from "@tanstack/react-query";

export interface ApiContextType {
  queryClient: ReturnType<typeof useQueryClient>;
  sites: UseQueryResult<
    (InferResponseType<(typeof client.api)["sites"]["$get"]> & {
      success: true;
    })["data"]["sites"]
  > | null;
  siteById: ({ siteId }: { siteId: string }) => UseQueryResult<
    (InferResponseType<(typeof client.api)["sites"][":siteId"]["$get"]> & {
      success: true;
    })["data"]["site"]
  > | null;
}

const ApiContext = createContext<ApiContextType>({
  queryClient: new QueryClient(),
  sites: null,
  siteById: () => null,
});

export const ApiProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const auth = useAuth();
  const queryClient = useQueryClient();
  const headers = {
    authorization: `Bearer ${auth.token || ""}`,
  };
  const siteById = ({ siteId }: { siteId: string }) =>
    useQuery({
      queryKey: ["site", siteId],
      queryFn: async () => {
        const siteByIdRequest = await client.api.sites[":siteId"].$get(
          {
            param: { siteId },
          },
          { headers }
        );
        const siteByIdResults = await siteByIdRequest.json();
        if (!siteByIdResults.success) {
          console.error(siteByIdResults.error);
          throw new Error(
            `Cannot fetch user sites: ${siteByIdResults.error.issues[0].message}`
          );
        }
        return siteByIdResults.data.site;
      },
    });
  const sites = useQuery({
    queryKey: ["sites"],
    queryFn: async () => {
      const sitesRequest = await client.api.sites.$get({}, { headers });
      const sitesResults = await sitesRequest.json();
      if (!sitesResults.success) {
        console.error(sitesResults.error);
        throw new Error(
          `Cannot fetch user sites: ${sitesResults.error.issues[0].message}`
        );
      }
      return sitesResults.data.sites;
    },
    enabled: !!auth.token,
    initialData: [],
  });

  return (
    <ApiContext.Provider value={{ queryClient, sites, siteById }}>
      {children}
    </ApiContext.Provider>
  );
};
export const useApi = (): ApiContextType => {
  const context = useContext(ApiContext);
  if (!context) {
    throw new Error("useApi must be used within an ApiProvider");
  }
  return context;
};
