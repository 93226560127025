import { useDocumentTitle } from "@uidotdev/usehooks";

import React, { FC, useEffect } from "react";
import { FaPlus } from "react-icons/fa";
import { config } from "../config";
import { ApiContextType, useApi, useTranslations } from "../contexts";
import { routerPaths } from "./routerPaths";

import { Link, Navigate } from "react-router-dom";
import { NonUndefined, ValuesType } from "utility-types";
import { Button } from "../components";

const SiteListItem: React.FC<{
  site: ValuesType<NonUndefined<NonNullable<ApiContextType["sites"]>["data"]>>;
}> = (props) => {
  const api = useApi();
  const site = api.siteById({ siteId: props.site.id });
  if (!site?.data) {
    return null;
  }
  return (
    <Link
      className="block max-w-sm border-2 p-5 rounded hover:bg-slate-100"
      to={routerPaths.siteManage({ siteId: site.data.id, tab: "general" })}
    >
      <h5 className="text-2xl font-bold">{site.data.name || site.data.id}</h5>
      <p className="font-normal text-gray-700 dark:text-gray-400">
        {site.data.hero?.description}
      </p>
    </Link>
  );
};

export const DashboardPage: FC = () => {
  const api = useApi();
  const i18n = useTranslations();
  useDocumentTitle(`${config.app.name} - ${i18n["dashboard"]}`);
  useEffect(() => {
    api.sites?.refetch();
  }, []);
  if (!api.sites?.isLoading && api.sites?.data?.length === 0) {
    return <Navigate to={routerPaths.siteNew} />;
  }
  return (
    <div>
      <h1 className="text-2xl font-bold border-b p-3 mb-6 flex">
        <p className="grow capitalize">{i18n["sites"]}</p>

        <Button linkTo={routerPaths.siteNew}>
          <div className="flex items-center gap-1 capitalize">
            <FaPlus className="mr-3 h-4 w-4" />
            {i18n.new}
          </div>
        </Button>
      </h1>
      <ul className="grid grid-cols-3 gap-3">
        {api.sites?.data?.map((site) => (
          <SiteListItem key={site.id} site={site} />
        ))}
      </ul>
    </div>
  );
};
