import React from "react";
import { Button, Input } from "../../../components";
import { useApi, useAuth, useTranslations } from "../../../contexts";
import { client } from "../../../helpers";
import { routerPaths } from "../../routerPaths";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

export const GeneralTab: React.FC = () => {
  const i18n = useTranslations();
  const navigate = useNavigate();
  const api = useApi();
  const { siteId } =
    useParams<Parameters<typeof routerPaths.siteManage>["0"]>();
  if (!siteId) throw new Error(`no site id`);
  const auth = useAuth();
  const deleteSite = async () => {
    const results = await (
      await client.api.sites[":siteId"].$delete({
        header: { authorization: `Bearer ${auth.token}` },
        param: { siteId },
      })
    ).json();
    if (!results.success) {
      toast.error(i18n["Something went wrong"]);
      console.error(results.error.issues[0].message);
    } else {
      await api.sites?.refetch();
      navigate(routerPaths.dashboard);
    }
  };
  return (
    <div>
      <Input type="text" placeholder="Name" variant="outlined" />
      <div className="mt-8 p-3   border rounded border-red-600">
        <h2 className="text-xl text-red-600 font-bold mb-4">Danger Zone</h2>
        <Button
          variant="destructive"
          onClick={(event) => {
            if (
              window.prompt(
                i18n[
                  "Are you sure you want to delete this site? To confirm, type DELETE."
                ]
              ) === i18n.confirmDeleteWord
            ) {
              deleteSite();
            } else {
              event.preventDefault();
            }
          }}
        >
          Delete
        </Button>
        <p className="mt-2 text-gray-600">
          {i18n["This action cannot be reverted or undone"]}
        </p>
      </div>
    </div>
  );
};
